import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { CardSecondary } from '../../components/molecules/Card/CardSecondary'
import { LayoutSecondary } from '../../components/organisms/Layout/LayoutSecondary'

const cardList = [
  {
    heading: '代表メッセージ',
    headingEnglish: ENGLISH_TITLE.CEOMessage,
    imagePath: '/company/img/companyTopCard1.png?v=2024-04-30',
    href: '/company/message',
  },
  {
    heading:
      '企業理念<span class="hidden tab:inline">・</span><br class="tab:hidden"/>行動指針',
    headingEnglish: ENGLISH_TITLE.Philosophy,
    // imagePath: '/company/img/companyTopCard2.png',//確定次第入れ替え
    imagePath: '/common/img/level2.png',
    href: '/company/philosophy',
  },
  {
    heading: '会社概要',
    headingEnglish: ENGLISH_TITLE.Outline,
    imagePath: '/company/img/companyTopCard3.png',
    href: '/company/summary',
  },
  {
    heading: 'CRGグループの強み',
    headingEnglish: ENGLISH_TITLE.OurStrengths,
    imagePath: '/company/img/companyTopCard4.png',
    href: '/company/strength',
  },
  {
    heading: '沿革',
    headingEnglish: ENGLISH_TITLE.History,
    imagePath: '/company/img/companyTopCard5.png',
    href: '/company/history',
  },
  {
    heading: '組織図',
    headingEnglish: ENGLISH_TITLE.Organization,
    imagePath: '/company/img/companyTopCard6.png',
    href: '/company/chart',
  },
  {
    heading:
      'グループ会社<span class="hidden tab:inline">・</span><br class="pc:hidden"/>拠点一覧',
    headingEnglish: ENGLISH_TITLE.GroupCompanies,
    imagePath: '/company/img/companyTopCard7.png',
    href: '/company/bases',
  },
]

const Page = () => {
  return (
    <LayoutSecondary
      heading="企業情報"
      headingEnglish={ENGLISH_TITLE.Company}
      imgPathPc="/company/img/companyMVpc.png"
      imgPathSp="/company/img/companyMVsp.png"
      BreadcrumbData={[{ title: '企業情報', url: '/company' }]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <div>
        <ul className="grid mx-auto grid-cols-2 gap-x-5 gap-y-6 pc:grid-cols-3 pc:gap-10">
          {cardList.map((card, i) => (
            <CardSecondary key={i} {...card} />
          ))}
        </ul>
      </div>
    </LayoutSecondary>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => {
  return <SEO title="企業情報" url="/company" />
}
