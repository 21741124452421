import React, { ReactNode } from 'react'
import { SideNav } from '../SideNav/SideNav'
import { layoutList } from '../../styles/layout'
import { MvChildTop } from '../Mv/MvChildTop'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
  heading: string
  headingEnglish: string
  imgPathPc: string
  imgPathSp: string
  BreadcrumbData: Array<{ title: string; url: string }>
  sideNavHeading: string
  sideNavList: Array<{ text: string; url: string }>
  mvText?: string
}

/**
 * 下層ページトップのレイアウト
 * @param {ReactNode} children
 * @param {string} heading
 * @param {string} headingEnglish
 * @param {string} imgPathPc
 * @param {string} imgPathSp
 * @param {Array} BreadcrumbData
 * @param {string} sideNavHeading
 * @param {Array} sideNavList
 * @param {string} mvText
 */
/** */
export const LayoutSecondary = ({
  children,
  heading,
  BreadcrumbData,
  imgPathPc,
  imgPathSp,
  headingEnglish,
  sideNavHeading,
  sideNavList,
  mvText,
}: Props) => {
  return (
    <LayoutRoot>
      <MvChildTop
        heading={heading}
        headingEnglish={headingEnglish}
        BreadcrumbData={BreadcrumbData}
        imgPc={imgPathPc}
        imgSp={imgPathSp}
        text={mvText}
      />
      <div
        css={layoutList.layoutInner}
        className="flex justify-between flex-col pc:flex-row pc:items-start"
      >
        <div className="pc:w-[932px] pb-14 relative">{children}</div>
        <div className="pc:w-[212px] w-full">
          <SideNav heading={sideNavHeading} linkList={sideNavList} />
        </div>
      </div>
    </LayoutRoot>
  )
}
